define('tm3/pods/components/matter/dashboard/scope-notes/component', ['exports', 'ember-changeset', 'tm-common/mixins/crud/error'], function (exports, _emberChangeset, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        notifications: Ember.inject.service(),
        notify: Ember.inject.service(),
        session: Ember.inject.service(),

        showExpandCollapse: false,

        init: function init() {
            this._super.apply(this, arguments);

            this.set('selectedEmployees', []);
        },
        didRender: function didRender() {
            this._super.apply(this, arguments);
            this.checkScroll();
        },


        checkScroll: Ember.observer('matter.scopeNote', function () {
            var _this = this;

            this.get('matter.scopeNote');

            Ember.run.later(function () {
                var note = document.querySelector('.scope-note-truncated');

                if (!note) return;

                if (note.clientHeight >= 220) {
                    _this.set('showExpandCollapse', note.scrollHeight >= note.clientHeight);
                } else {
                    _this.set('showExpandCollapse', false);
                }
            }, 500);
        }),

        actions: {
            edit: function edit() {
                this.set('editableMatter', new _emberChangeset.default(this.get('matter')));
                this.set('editing', true);
            },
            save: function save() {
                var _this2 = this;

                var editableMatter = this.get('editableMatter');

                editableMatter.save().then(function () {
                    // send notification to selectedEmployees
                    var userIds = _this2.get('selectedEmployees').mapBy('id').join(',');

                    var matter = _this2.get('matter');

                    var createdBy = _this2.get('session.data.authenticated.last_name') + ', ' + _this2.get('session.data.authenticated.first_name');

                    var metadata = {
                        matters_scope_note: matter.get('scopeNote'),
                        matters_id: matter.get('id'),
                        matters_tm_code: matter.get('tmCode'),
                        matters_person_of_interest: matter.get('personOfInterest'),
                        matters_case_nickname: matter.get('caseNickname')
                    };

                    var subject = createdBy + ' updated the scope of case ' + matter.get('tmCode');

                    _this2.get('notifications').send('ScopeUpdate', userIds, subject, matter.get('scopeNote'), 'matter', matter.get('id'), matter.get('id'), JSON.stringify(metadata));

                    _this2.get('notify').success('Saved successfully');
                    _this2.set('editing', false);
                }).catch(function () {
                    _this2.validationReport(editableMatter);
                });
            }
        }
    });
});