define('tm3/pods/matter/documents/keywords-list-search/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),
        ajax: Ember.inject.service(),

        queryParams: {
            kl: {
                refreshModel: true
            },
            kw: {
                refreshModel: true
            },
            s: {
                refreshModel: true
            },
            docs: {
                refreshModel: true
            }
        },

        model: function model(params) {
            return Ember.RSVP.hash({
                params: params,
                extraDocuments: params.docs ? this.store.query('document', {
                    subject_id: params.s || null,
                    resource_id: params.docs
                }) : [],
                matches: this.get('ajax').post('report_builder', {
                    data: {
                        json_post: JSON.stringify({
                            report_builder: {
                                standard: {
                                    type: 'keywords_search',
                                    format: 'json',
                                    style: 'misc'
                                },
                                custom: {
                                    matter_id: this.get('currentMatter.matter.id'),
                                    subject_id: params.s || null,
                                    keyword_list_id: params.kl,
                                    keywords: params.kw
                                }
                            }
                        })
                    }
                })
            });
        },
        setupController: function setupController(controller, model) {
            this._super(controller, model);
            var documentMapList = controller.get('documentMapList');

            if (model.params.docs) {
                controller.set('documentFilter', documentMapList.filter(function (doc) {
                    return model.params.docs.split(',').includes(doc.id.toString());
                }).map(function (doc) {
                    return doc.name;
                }).uniq().sort());
            }
            var sorted = controller.get('filterAndSortedMatches');

            controller.send('showDocument', sorted.get('firstObject'));
        },
        renderTemplate: function renderTemplate() {
            this.render({ into: 'matter' });
        },
        activate: function activate() {
            this.controllerFor('matter.documents.keywords-list-search').startListeningKeyboard();
        },
        deactivate: function deactivate() {
            this.controllerFor('matter.documents.keywords-list-search').stopListeningKeyboard();
        }
    });
});