define('tm3/pods/matter/documents/keywords-list-search/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Controller.extend({
        queryParams: ['kl', 's', 'kw', 'docs'],
        kl: null,
        kw: null,
        s: null,
        docs: null,

        currentDocumentId: null,
        currentMatch: null,

        sortField: 'document',
        sortDirection: true,
        kwf: null,
        nkwf: null,
        df: null,
        dtf: null,
        hf: null,

        keywordFilter: [],
        noKeywordFilter: [],
        documentFilter: [],
        documentTypeFilter: [],
        highlightFilter: '',

        keywordList: Ember.computed('model.matches', function () {
            return this.model.matches.map(function (match) {
                return match.keyword;
            }).uniq().sort();
        }),

        documentList: Ember.computed('documentMapList', function () {
            return this.get('documentMapList').map(function (doc) {
                return doc.name;
            }).uniq().sort();
        }),

        documentMapList: Ember.computed('model.matches', 'model.extraDocuments', function () {
            var fromMatches = this.model.matches.map(function (match) {
                return {
                    id: match.document_id,
                    name: match.document_name,
                    type: match.document_type
                };
            }).uniqBy('id');

            var fromExtraDocs = this.model.extraDocuments.map(function (doc) {
                return {
                    id: parseInt(doc.get('id'), 10),
                    name: doc.get('name'),
                    type: doc.get('docType')
                };
            })
            // don't duplicate docs that are already in fromMatches
            .filter(function (source) {
                return !fromMatches.find(function (target) {
                    return source.id === target.id;
                });
            });

            return [].concat(_toConsumableArray(fromMatches), _toConsumableArray(fromExtraDocs));
        }),

        documentTypeList: Ember.computed('documentMapList', function () {
            return this.get('documentMapList').map(function (doc) {
                return doc.type;
            }).uniq().sort();
        }),

        keywordsStats: Ember.computed('filterAndSortedMatches', function () {
            return this.get('filterAndSortedMatches').reduce(function (acc, match) {
                acc[match.keyword] = acc[match.keyword] ? acc[match.keyword] + 1 : 1;

                return acc;
            }, {});
        }),

        hasFilters: Ember.computed('keywordFilter.[]', 'noKeywordFilter.[]', 'documentTypeFilter.[]', 'documentFilter.[]', 'highlightFilter', function () {
            return this.keywordFilter.length || this.noKeywordFilter.length || this.documentTypeFilter.length || this.documentFilter.length || this.highlightFilter;
        }),

        appliedFilters: Ember.computed('keywordFilter.[]', 'noKeywordFilter.[]', 'documentTypeFilter.[]', 'documentFilter.[]', 'highlightFilter', function () {
            var filters = [];

            if (this.keywordFilter.length) {
                filters["Keywords"] = this.keywordFilter.join(', ');
            }
            if (this.noKeywordFilter.length) {
                filters["Excluded Keywords"] = this.noKeywordFilter.join(', ');
            }
            if (this.documentTypeFilter.length) {
                filters["Document Types"] = this.documentTypeFilter.join(', ');
            }
            if (this.documentFilter.length) {
                filters["Documents"] = this.documentFilter.join(', ');
            }
            if (this.highlightFilter) {
                filters["Highlight"] = this.highlightFilter;
            }

            return filters;
        }),

        filterAndSortedMatches: Ember.computed('model.matches', 'sortField', 'sortDirection', 'keywordFilter', 'noKeywordFilter', 'documentTypeFilter', 'documentFilter', 'highlightFilter', function () {
            var _this = this;

            var records = this.model.matches.filter(function () {
                return true;
            });

            if (this.keywordFilter.length > 0) {
                records = records.filter(function (rec) {
                    return _this.keywordFilter.includes(rec.keyword);
                });
            }

            if (this.noKeywordFilter.length > 0) {
                records = records.filter(function (rec) {
                    return !_this.noKeywordFilter.includes(rec.keyword);
                });
            }

            if (this.documentTypeFilter.length > 0) {
                records = records.filter(function (rec) {
                    return _this.documentTypeFilter.includes(rec.document_type);
                });
            }

            if (this.documentFilter.length > 0) {
                records = records.filter(function (rec) {
                    return _this.documentFilter.includes(rec.document_name);
                });
            }

            if (this.highlightFilter) {
                records = records.filter(function (rec) {
                    return rec.highlight.toLowerCase().indexOf(_this.highlightFilter.toLowerCase()) >= 0;
                });
            }

            switch (this.sortField) {
                case 'keyword':
                case 'highlight':
                    records = records.sortBy(this.sortField);

                    break;
                case 'document':
                    records = records.sortBy('document_name', 'page');

                    break;
            }

            return this.sortDirection ? records : records.reverse();
        }),

        actions: {
            showDocument: function showDocument(match) {
                this.set('currentMatch', match);

                if (!match) {
                    this.set('document', null);
                    this.set('currentDocumentId', null);
                    return;
                }

                var goToPageAfterLoad = function goToPageAfterLoad(e) {
                    var target = e.detail.source.PDFViewerApplication;

                    target.initializedPromise.then(function () {
                        target.eventBus.on('pagesloaded', function () {
                            // go to page
                            if (match.page > 0) {
                                target.pdfViewer.scrollPageIntoView({ pageNumber: match.page });
                            }

                            // highlight search term
                            target.eventBus.dispatch('find', { type: '', query: match.keyword, highlightAll: true });
                        });
                    });
                };

                if (this.currentDocumentId === match.document_id) {
                    if (!window.frames[1]) {
                        // Frame not present
                        return;
                    }

                    // The PDF is already loaded
                    var target = window.frames[1].PDFViewerApplication;

                    // go to page
                    if (match.page > 0) {
                        target.pdfViewer.scrollPageIntoView({ pageNumber: match.page });
                    }

                    // highlight search term
                    target.eventBus.dispatch('find', { type: '', query: match.keyword, highlightAll: true });

                    return;
                }
                document.removeEventListener('webviewerloaded', goToPageAfterLoad);

                document.addEventListener('webviewerloaded', goToPageAfterLoad);

                this.set('document', Ember.Object.create({
                    id: match.document_id,
                    name: match.document_name,
                    subject: match.subject_name
                }));

                this.set('currentDocumentId', match.document_id);
            },
            setNewFilters: function setNewFilters() {
                this.set('keywordFilter', this.kwf);
                this.set('noKeywordFilter', this.nkwf);
                this.set('documentTypeFilter', this.dtf);
                this.set('documentFilter', this.df);
                this.set('highlightFilter', this.hf);

                this.send('showDocument', this.get('filterAndSortedMatches').get('firstObject'));

                this.set('kwf', null);
                this.set('nkwf', null);
                this.set('dtf', null);
                this.set('df', null);
                this.set('hf', null);
            },
            clearFilters: function clearFilters() {
                this.set('kwf', []);
                this.set('nkwf', []);
                this.set('dtf', []);
                this.set('df', []);
                this.set('hf', null);

                this.set('keywordFilter', this.kwf);
                this.set('noKeywordFilter', this.nkwf);
                this.set('documentTypeFilter', this.dtf);
                this.set('documentFilter', this.df);
                this.set('highlightFilter', this.hf);
            },
            showFilterModal: function showFilterModal() {
                this.set('kwf', this.keywordFilter);
                this.set('nkwf', this.noKeywordFilter);
                this.set('dtf', this.documentTypeFilter);
                this.set('df', this.documentFilter);
                this.set('hf', this.highlightFilter);
                this.set('showAdvFilter', true);
            }
        },

        startListeningKeyboard: function startListeningKeyboard() {
            var _this2 = this;

            document.onkeydown = function (e) {
                if (e.key === 'ArrowDown') {
                    var index = _this2.currentMatch ? _this2.get('filterAndSortedMatches').indexOf(_this2.currentMatch) : -1;

                    if (index >= -1 && index < _this2.get('filterAndSortedMatches').length - 1) {
                        _this2.send('showDocument', _this2.get('filterAndSortedMatches')[index + 1]);
                    }
                } else if (e.key === 'ArrowUp') {
                    var _index = _this2.currentMatch ? _this2.get('filterAndSortedMatches').indexOf(_this2.currentMatch) : _this2.get('filterAndSortedMatches').length;

                    if (_index > 0 && _index <= _this2.get('filterAndSortedMatches').length) {
                        _this2.send('showDocument', _this2.get('filterAndSortedMatches')[_index - 1]);
                    }
                }
            };
        },
        stopListeningKeyboard: function stopListeningKeyboard() {
            document.onkeydown = null;
        }
    });
});