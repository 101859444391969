define('tm3/pods/components/edit-admin-field/component', ['exports', 'tm-common/mixins/crud/error'], function (exports, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error.default, {
        notify: Ember.inject.service(),
        isAdminPage: false,
        field: null,
        publicLists: null,
        iconList: null,
        matterFieldInputTypes: null,

        sectionOptions: [{ display: 'OFF', value: 0 }, { display: 'Vital', value: 1 }, { display: 'Overview', value: 2 }],
        uneditableFields: ['published_on', 'published_by'], // these should not appear in add/edit forms so no need to change their field type

        allowEditFieldType: Ember.computed('field', function () {
            var field = this.get('field');
            var uneditableFields = this.get('uneditableFields');
            return !uneditableFields.includes(field.get('value'));
        }),

        filteredMatterFieldInputTypes: Ember.computed('field.fieldClass', 'matterFieldInputTypes.[]', function () {
            var matterFieldInputTypes = this.get('matterFieldInputTypes');
            return this.get('field.fieldClass') === 'subject-custom' ? matterFieldInputTypes : matterFieldInputTypes.rejectBy('value', 'analytics');
        }),

        initSelectFields: Ember.on('init', function () {
            var field = this.get('field');
            var selectedMergeField = this.get('selectableFields').findBy('value', field.get('mergeField'));

            field.set('selectedMergeField', selectedMergeField);
            field.set('selectedFieldSection', this.get('sectionOptions').findBy('value', field.get('section')));

            if (this.get('fieldHistoryRecords.length') > 0) {
                this.set('historyField', this.get('fieldHistoryRecords.firstObject'));
            }

            // this.set('columnWidth', field.get('columnWidth'))
        }),

        modalTitle: Ember.computed('field', 'isAdminPage', function () {
            if (this.get('isAdminPage')) {
                return this.get('field.fieldDisplay');
            } else {
                return this.get('field.display');
            }
        }),

        isFieldTypeDisabled: Ember.computed('field', 'field.isUncustomizable', 'field.isDisabledCase', 'field.fieldClass', function () {
            var field = this.get('field');
            return field.get('isDisabledCase') || field.get('isUncustomizable') || field.get('fieldClass') === 'widget';
        }),

        isListSelectDisabled: Ember.computed('field', 'field.isDisabledCase', 'field.fieldType', 'field.isUncustomizable', 'field.fieldClass', function () {
            var field = this.get('field');
            var fieldType = field.get('fieldType');
            return field.get('isDisabledCase') || field.get('isUncustomizable') || field.get('fieldClass') === 'widget' || fieldType !== 'enum' && fieldType !== 'radio' && fieldType !== 'checkboxes';
        }),

        selectableFields: Ember.computed('allFields', function () {
            var fields = [];
            var currentFieldName = this.get('modalTitle');

            var fieldName = '';
            var fieldValue = '';
            if (this.get('isAdminPage')) {
                fieldName = 'fieldDisplay';
                fieldValue = 'fieldName';
            } else {
                fieldName = 'display';
                fieldValue = 'value';
            }

            this.get('allFields').forEach(function (field) {
                if (currentFieldName !== field.get(fieldName) && field.get('isEnabledCase')) {
                    fields.push({ display: field.get(fieldName), value: field.get(fieldValue) });
                }
            });

            return fields;
        }),

        colSize: Ember.computed('isAdminPage', function () {
            if (this.get('isAdminPage')) {
                return 'col-sm-6';
            } else {
                return 'col-sm-4';
            }
        }),

        displayHistory: Ember.computed('isAdminPage', function () {
            if (this.get('isAdminPage')) {
                return 'hidden';
            } else {
                return '';
            }
        }),

        validateFields: function validateFields() {
            var valid = true;
            // if fieldType is checkgox radio or selectbox
            if (this.get('allowEditFieldType') && !this.get('isListSelectDisabled')) {
                valid = !Ember.isEmpty(this.get('field.listName'));
            }
            return valid;
        },


        actions: {
            /**
             * this save function check if the field to
             * be saved is dirty so we need to save,
             * if it was enabled before means that we need
             * to remove the record from 'matter-field'
             * if not just send to save to the server
             */
            save: function save() {
                var _this = this;

                // TODO: check save when on matters/admin/fields and user changed something from select!!!!
                var field = this.get('field');
                if (field.get('hasDirtyAttributes')) {
                    // validate data
                    if (!field.get('isEnabledCase') && !this.get('isAdminPage')) {
                        if (field.get('value').indexOf('custom') >= 0 && field.get('value').capitalize() !== field.get('display')) {
                            if (!window.confirm('Warning, by removing this custom field, you will lose the name of the custom field (it will revert to "' + field.get('value').capitalize() + '")')) {
                                return;
                            }
                        }
                        field.destroyRecord().then(function () {
                            _this.get('notify').success('Field Removed');
                            _this.send('fetchModel');
                            _this.send('modalClosed');
                        }, function () {
                            _this.validationReport(field);
                        });
                    } else {
                        if (!this.validateFields()) {
                            this.get('notify').error('You must select a list');
                            return false;
                        }
                        // in Admin-> Mater-fields : we set display name back to "custom.." when disabling custom field
                        if (this.get('isAdminPage') && !field.get('isEnabledCase')) {
                            if (field.get('fieldName').indexOf('custom') >= 0 && field.get('fieldName').capitalize() !== field.get('fieldDisplay')) {
                                if (!window.confirm('Warning, by removing this custom field, you will lose the name of the custom field (it will revert to "' + field.get('fieldName').capitalize() + '")')) {
                                    return;
                                } else {
                                    field.set('fieldDisplay', field.get('fieldName').capitalize());
                                }
                            }
                        }
                        field.save().then(function () {
                            _this.send('modalClosed');
                            _this.get('notify').success('Field Saved');
                            _this.send('fetchModel');
                        }, function () {
                            _this.validationReport(field);
                        });
                    }
                } else {
                    this.send('modalClosed');
                }
            },


            /**
             * deal with change to a HTML input field
             *
             * @param field
             * @param newFieldType
             */
            changeType: function changeType(field, newFieldType) {
                if (newFieldType) {
                    field.set('fieldType', newFieldType.get('value'));
                    var fieldType = newFieldType.get('value');

                    if (fieldType !== 'enum' && fieldType !== 'radio' && fieldType !== 'checkboxes') {
                        // reset list value
                        field.set('listName', '');
                    }
                }
                field.set('selectedFieldType', newFieldType);
            },


            /**
             * deal with change to a HTML input field
             *
             * @param field
             * @param parentField
             */
            changeParent: function changeParent(field, parentField) {
                if (parentField) {
                    field.set('mergeField', parentField.value);
                } else {
                    field.set('mergeField', null);
                }
                field.set('selectedMergeField', parentField);
            },
            changeSection: function changeSection(field, newSection) {
                field.set('section', newSection.value);
                field.set('selectedFieldSection', newSection);
            },


            /**
             * deal with change to the icon select
             *
             * @param field
             * @param newFieldType
             */
            changeIcon: function changeIcon(field, icon) {
                if (icon) {
                    field.set('iconFile', icon.get('value'));
                    field.set('selectedIconFile', icon);
                } else {
                    field.set('iconFile', 'None');
                    field.set('selectedIconFile', this.get('iconList').findBy('value', 'None'));
                }
            },
            fetchModel: function fetchModel() {
                this.sendAction('fetchModel');
            },
            setIsLoadingTrue: function setIsLoadingTrue() {
                this.sendAction('setIsLoadingTrue');
            },
            cancel: function cancel() {
                var field = this.get('field');
                if (field.get('hasDirtyAttributes')) {
                    this.send('setIsLoadingTrue');
                    if (!field.get('isNew')) {
                        field.rollbackAttributes();
                    }
                }
                this.send('modalClosed');
            },
            modalClosed: function modalClosed() {
                this.sendAction('modalClosed');
            },
            validateNumber: function validateNumber(number) {
                var field = this.get('field');
                field.set('columnWidth', number.replace(/[^\d]+/, ''));
            },
            diffValues: function diffValues(field, historyField, attribute) {
                if (!historyField) {
                    return '';
                }
                return field.get(attribute) !== historyField.get(attribute) ? 'text-is-diff' : '';
            }
        }
    });
});